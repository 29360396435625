import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../context/auth-context';
import { getIssue, getNotesByIssue, getRelatedIssues } from '../services/issue';

export default function UseIssue() {
  const { getAuthToken } = useContext(AuthContext);
  const { issueId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [issue, setIssue] = useState();
  const [comments, setComments] = useState([]);
  const [labels, setLabels] = useState([]);
  const [relatedIssues, setRelatedIssues] = useState([]);

  const loadData = async () => {
    try {
      const issueResp = await getIssue(issueId, getAuthToken());
      setIssue(issueResp);
      const relIssues = await getRelatedIssues(issueId, getAuthToken());
      setRelatedIssues(relIssues);
      const commentsResp = await getNotesByIssue(issueId, getAuthToken());
      setComments(commentsResp);
      setLabels(issueResp.labels);
    } catch (loadError) {
      console.error(loadError);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [issueId]);

  return {
    loading,
    error,
    issue,
    comments,
    labels,
    relatedIssues,
  };
}
