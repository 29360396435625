import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getIssuesOfGroup = (id, code) => {
  return axios.get(`${API_URL}groups/${id}/issues?code=${code}`)
    .then((resp) => resp.data);
};

export const getGroup = (id, code) => {
  return axios.get(`${API_URL}groups/${id}?code=${code}`)
    .then((resp) => {
      return resp.data;
    });
};

export default {
  getIssuesOfGroup,
  getGroup,
};
