import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getGroup, getIssuesOfGroup } from '../../services/group';
import BoardColumn from '../../components/board-column';
import './styles.scss';
import AuthContext from '../../context/auth-context';

export default function Board() {
  const { getAuthToken } = useContext(AuthContext);

  const { groupId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [group, setGroup] = useState();
  const [boardColumns, setBoardColumns] = useState([]);

  const loadData = async () => {
    try {
      const groupResp = await getGroup(groupId, getAuthToken());
      setGroup(groupResp);
      const issuesResp = await getIssuesOfGroup(groupId, getAuthToken());
      const columns = [];
      groupResp.boardColumns.forEach((columnLabel, idx) => {
        columns[idx] = {
          title: columnLabel,
          label: columnLabel,
          issues: [],
        };
      });
      const closedIssues = [];
      issuesResp.forEach((elemIssue, idx) => {
        if (elemIssue.state === 'closed') {
          return closedIssues.push(elemIssue);
        }
        const issueColumn = columns.find((elemColumn) => {
          return elemIssue.labels
            .map((elem) => elem.toLowerCase())
            .includes(elemColumn.label.toLowerCase());
        });
        if (!issueColumn) {
          return columns[0].issues.push(elemIssue);
        }
        issuesResp[idx].labels = elemIssue.labels
          .filter((elemLabel) => elemLabel.toLowerCase() !== issueColumn.label.toLowerCase());
        return issueColumn.issues.push(issuesResp[idx]);
      });
      columns.push({
        title: 'Closed',
        issues: closedIssues,
      });
      setBoardColumns(columns);
    } catch (loadError) {
      console.error(loadError);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (getAuthToken() !== '') {
      loadData();
    } else {
      setError(true);
      setLoading(false);
    }
  }, [groupId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>error</p>;
  }

  return (
    <div className="screen-board">
      <h2>
        { group.name }
        <span>
          { group.description }
        </span>
      </h2>
      <div className="content-column-task">
        {
          boardColumns.map((column) => {
            return (
              <div className="item" key={`column-item-${column.title}`}>
                <BoardColumn
                  title={column.title}
                  issues={column.issues}
                />
              </div>
            );
          })
        }
      </div>
    </div>
  );
}
