import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Labels from '../labels/labels';
import { formatDateFromString } from '../../services/dates';
import './styles.scss';
import AuthContext from '../../context/auth-context';
import calendar from '../../assets/calendar_alt.svg';

export default function BoardTask({
  id,
  iid,
  title,
  createdAt,
  closedAt,
  labels,
}) {
  const { getAuthToken } = useContext(AuthContext);
  return (
    <div className="component-board-task">
      <Link to={`/issue/${id}?code=${getAuthToken()}`} className="linker" target="_blank">
        <div className="task-box">
          <div className="title-content">
            <h4 className="title">
              {title}
            </h4>
            <h4 className="id-label">
              {`#${iid}`}
            </h4>
          </div>
          {
            labels && labels.length > 0 && (
              <div className="tags-content">
                <Labels labels={labels} />
              </div>
            )
          }
          <div className="bottom-content">
            <p className="date-label">
              <img src={calendar} alt="creation date" />
              {formatDateFromString(createdAt)}
            </p>
            {
              closedAt && (
                <p className="date-label">
                  <img src={calendar} alt="closed date" />
                  {formatDateFromString(closedAt)}
                </p>
              )
            }
          </div>
        </div>
      </Link>
    </div>
  );
}
