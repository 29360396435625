import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getIssue = (id, code) => {
  return axios.get(`${API_URL}issues/${id}?code=${code}`)
    .then((resp) => resp.data);
};

export const getRelatedIssues = (id, code) => {
  return axios.get(`${API_URL}issues/${id}/linked?code=${code}`)
    .then((resp) => resp.data);
};

export const getNotesByIssue = (id, code) => {
  return axios.get(`${API_URL}issues/${id}/notes?code=${code}`)
    .then((resp) => resp.data);
};

export default {
  getIssue,
  getRelatedIssues,
  getNotesByIssue,
};
