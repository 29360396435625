import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import calendar from '../../assets/calendar.svg';
import BoardTask from '../../components/board-task';
import { formatDateFromString } from '../../services/dates';
import './styles.scss';
import Labels from '../../components/labels/labels';
import UseIssue from '../../hooks/use-issue';

export default function Issue() {
  const {
    loading,
    error,
    issue,
    comments,
    labels,
    relatedIssues,
  } = UseIssue();

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error!</p>;
  }

  return (
    <div className="screen-issue">
      <div className="title-labels-box fadeIn">
        <h2>
          <span>
            #
            { issue.iid }
          </span>
          { issue.title }
        </h2>
      </div>
      <div className="main-info">

        <div className="tags-box">
          <div>
            <span>Tags</span>
          </div>
          <div>
            {
            labels.length === 0
              ? <span className="no-labels">This issue has no labels</span>
              : <Labels labels={labels} />
            }
          </div>
        </div>

        <div className="dates-box">
          <div>
            <span>Created at</span>
          </div>
          <div>
            <span className="date">
              <img src={calendar} alt="calendar" />
              { formatDateFromString(issue.createdAt) }
            </span>
          </div>
        </div>

        {
          issue.closedAt && (
            <div className="dates-box">
              <div>
                <span>Deadline</span>
              </div>
              <div>
                <img src={calendar} alt="calendar" />
                <span className="date">
                  { formatDateFromString(issue.closedAt) }
                </span>
              </div>
            </div>
          )
        }
      </div>
      <div className="issue-info-container">
        <div className="issue-description-comments-container">
          <div className="header">
            <h3 className="title">Description</h3>
          </div>
          <div className="card-box details description fadeIn">

            <ReactMarkdown className={`markdown-content ${issue.description || 'no-description'}`} rehypePlugins={[rehypeRaw]}>
              { issue.description || 'No description' }
            </ReactMarkdown>
          </div>

          <div className="comments-title">
            <h3 className="title">Comments</h3>
            <span>{comments.length}</span>
          </div>
          <div className="card-box details comments fadeIn">
            {
              comments.length === 0
                ? (
                  <div className="no-comments">
                    <span>No comments yet</span>
                  </div>
                )
                : comments.map((comment) => {
                  return (
                    <div className="comment-box" key={comment.id}>
                      <div className="author-container">
                        <h4 className="author">
                          { !comment.fromClient && `${comment.authorName} -` }
                        </h4>
                        <p>
                          {formatDateFromString(comment.createdAt)}
                        </p>
                      </div>
                      <ReactMarkdown className="body markdown-content" rehypePlugins={[rehypeRaw]}>
                        { comment.body }
                      </ReactMarkdown>
                    </div>
                  );
                })
            }
          </div>
        </div>

        <div className="related-issues-container">
          <div className="header">
            <h3 className="title">Related:</h3>
          </div>
          {
            relatedIssues.length === 0
              ? (
                <div className="no-related-issues">
                  <span>No related issues yet</span>
                </div>
              )
              : relatedIssues.map((relIssue) => {
                return (
                  <BoardTask
                    key={relIssue.id}
                    id={relIssue.id}
                    title={relIssue.title}
                    createdAt={relIssue.createdAt}
                    closedAt={relIssue.closedAt}
                    labels={relIssue.labels}
                  />
                );
              })
          }
        </div>
      </div>
    </div>
  );
}
