import React from 'react';
import BoardTask from '../board-task';
import './styles.scss';

export default function BoardColumn({ title, issues }) {
  return (
    <div className="component-board-column card-box">
      <div className="header">
        <h3>{title}</h3>
        <span>{issues.length}</span>
      </div>
      <div className="content-task-column">
        {
          issues.map((item) => {
            return (
              <BoardTask
                key={item.id}
                id={item.id}
                iid={item.iid}
                title={item.title}
                createdAt={item.createdAt}
                closedAt={item.closedAt}
                labels={item.labels}
              />
            );
          })
        }
      </div>
    </div>
  );
}
