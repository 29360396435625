import React from 'react';
import './styles.scss';

export default function Labels({ labels, index }) {
  return (
    <div className="labels">
      {labels.map((label) => {
        return <span key={`${label}-${index}`} className="label">{label}</span>;
      })}
    </div>
  );
}
