import React from 'react';
import './styles.scss';

export default function Navbar() {
  return (
    <nav className="navbar horizontal-container">
      <h1>
        Opus
        <span>
          by GRAVA
        </span>
      </h1>
    </nav>
  );
}
