import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import { AuthProvider } from './context/auth-context';
import Board from './screens/board';
import Issue from './screens/issue';

export default function App() {
  return (

    <BrowserRouter>
      <AuthProvider>
        <Navbar />
        <div className="body-container horizontal-container">
          <Routes>
            <Route path="/issue/:issueId" element={<Issue />} />
            <Route path="/:groupId" element={<Board />} />
          </Routes>
        </div>
      </AuthProvider>
    </BrowserRouter>
  );
}
