import { React, createContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const AuthContext = createContext();

function AuthProvider({ children }) {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code = query.get('code');

  const getAuthToken = () => {
    return code;
  };

  const data = { getAuthToken };

  return <AuthContext.Provider value={useMemo(() => data)}>{ children }</AuthContext.Provider>;
}

export { AuthProvider };
export default AuthContext;
